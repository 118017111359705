import { graphql } from 'gatsby'
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify"
import "./index.scss"
import BackButton from "../images/backButton.png"

const ImageViewer = props => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    fetchImage()
  }, [])
  async function fetchImage() {
    try {
      const image = await Storage.get("thumbnail" + props.image)
      setImage(image)
    } catch {
      const image = await Storage.get(props.image)
      setImage(image)
    }
  }
  return image && <img width="70" height="70" src={image}></img>
}

const TVStationsPage = props => {
  useEffect(() => {
    var markers = []
    var markerPositions = []
    const allProjects = [
      props.data.project.station1,
      props.data.project.station2,
      props.data.project.station3,
      props.data.project.station4,
    ]
    allProjects.map(project => {
      if (project.location !== null) {
        markers.push({
          position: [project.location.lat, project.location.lon],
          text: project.title,
          id: project.id,
          onHubs: project.onHubs,
          featured: project.featured,
        })
        markerPositions.push([project.location.lat, project.location.lon])
      }
    })
    props.addMarker(markers)
    // props.flyToBounds(markerPositions)
    return () => {
      props.flyToBounds([])
      props.addMarker([])
    }
  }, [])

  const allProjects = [
    props.data.project.station1,
    props.data.project.station2,
    props.data.project.station3,
    props.data.project.station4,
  ]

  return (
    <section className="homepage">
      <SEO title="TV" />
      <div className="padded">
        <Link to="/">
          <img className="back-button" src={BackButton}></img>
        </Link>
        <h1>TV</h1>
        {/* <p>Ars Electronica</p> */}
      </div>
      <div className="featured">
        {allProjects.map(project => (
          <div key={project.id} className="project">
            <Link to={"/project/" + project.id}>
              <ImageViewer image={project.image} />
              <div className="title-and-description">
                <div className="title">{project.title}</div>
                <div className="subtitle">{project.subtitle}</div>
                <div className="description">
                  {project.description.substring(0, 55) + "..."}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* <h2>
        <Link to="/beammeup">Beam me up!</Link>
      </h2>
      <h2>
        <Link to="/page2">Page 2</Link>
      </h2> */}
      {/* <div className="padded"></div>
      <Link to="/partner/submit">Submit a project</Link> <br /> */}
    </section>
  )
}
export const query = graphql`
  {
    project {
      station1: getProject(id: "00375563-7a7e-4931-bb66-5e86332e1b76") {
        id
        image
        title
        subtitle
        description
        location {
          lat
          lon
        }
      }
      station2: getProject(id: "6d2c1701-8eca-4407-9fdd-12c7c98c80db") {
        id
        image
        title
        subtitle
        description
        location {
          lat
          lon
        }
      }
      station3: getProject(id: "8080680f-f12b-4f74-933c-95cb090fafe1") {
        id
        image
        title
        subtitle
        description
        location {
          lat
          lon
        }
      }
      station4: getProject(id: "4d9b2e42-7092-4006-8e8c-a582c77cc0c1") {
        id
        image
        title
        subtitle
        description
        location {
          lat
          lon
        }
      }
    }
  }
`

export default TVStationsPage
